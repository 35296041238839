import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import {useDispatch, useSelector} from "react-redux";
import useOnClickOutside from "use-onclickoutside";
import {addMonths, addDays, isTomorrow, isToday, startOfTomorrow, format, differenceInDays, startOfToday} from "date-fns";

import * as actionTypes from '../../../store/actions/actionTypes';
import useAdmissionListNavigation from "../../../utils/admissionListNavigation";
import AdmissionList_Tab_TestHospital from "./AdmissionList_Tab_TestHospital";
import {useCustomLogic} from "../../../utils/customLogic";
import AdmissionList_Tab_Lifehouse from "./AdmissionList_Tab_Lifehouse";

const DATE_SELECTOR_ALL = 'all';
const DATE_SELECTOR_RECENT = 'recent';
const DATE_SELECTOR_THIS_MONTH = 'this-month';
const DATE_SELECTOR_NEXT_MONTH = 'next-month';
const DATE_SELECTOR_THIS_WEEK = 'this-week';
const DATE_SELECTOR_NEXT_WEEK = 'next-week';

const AdmissionList_Tabs = () => {
    const [datePopupVisible, setDatePopupVisible] = useState(false);
    const admissionListVars = useSelector(state => state.admissionList);
    const selectedHospital = useSelector(state => state.auth.selectedHospital);
    const dispatch = useDispatch();
    const {pushAdmissionsListUrl} = useAdmissionListNavigation();
    const {isCustomLogicLifehouse} = useCustomLogic();

    const state_showDevUI = useSelector(state => state.auth.state_showDevUI);

    const ref = React.useRef(null);
    useOnClickOutside(ref, () => setDatePopupVisible(false));

    const showDatePopup = (event) => {
        setDatePopupVisible(!datePopupVisible);
        event.preventDefault();
    }

    const selectDate = date => {
        setDatePopupVisible(false);
        pushAdmissionsListUrl(null, date, '', null,null, false, false);
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_DATE, date})
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_KEYWORD})
    }
    const selectDateSelector = dateSelector => {
        setDatePopupVisible(false);
        pushAdmissionsListUrl(null, null, '', null,null, 'd', dateSelector);
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_KEYWORD})
        dispatch({type: actionTypes.ADMISSIONS_LIST_SET_GROUP, groupType: 'd', groupId: dateSelector})
    }

    const selectToday = event => {
        if (!isDayToday()) {
            selectDate(new Date());
        }
        event.preventDefault();
    }
    const selectTomorrow = event => {
        if (!isDayTomorrow()) {
            selectDate(startOfTomorrow());
        }
        event.preventDefault();
    }
    const selectDayPlus2 = event => {
        if (!admissionListVars.date || !isDayPlus2() || admissionListVars.keyword) {
            selectDate(addDays(new Date(), '2'));
        }
        event.preventDefault();
    }
    const selectDayPlus3 = event => {
        if (!admissionListVars.date || !isDayPlus3() || admissionListVars.keyword) {
            selectDate(addDays(new Date(), '3'));
        }
        event.preventDefault();
    }
    const selectAll = event => {
        if (!isDayAll()) {
            selectDateSelector(DATE_SELECTOR_ALL);
        }
        event.preventDefault();
    }
    const selectRecent = event => {
        if (!isDayRecent()) {
            selectDateSelector(DATE_SELECTOR_RECENT);
        }
        event.preventDefault();
    }
    const selectThisMonth = event => {
        if (!isDayThisMonth()) {
            selectDateSelector(DATE_SELECTOR_THIS_MONTH);
        }
        event.preventDefault();
    }
    const selectNextMonth = event => {
        if (!isDayNextMonth()) {
            selectDateSelector(DATE_SELECTOR_NEXT_MONTH);
        }
        event.preventDefault();
    }
    const selectThisWeek = event => {
        if (!isDayThisWeek()) {
            selectDateSelector(DATE_SELECTOR_THIS_WEEK);
        }
        event.preventDefault();
    }
    const selectNextWeek = event => {
        if (!isDayNextWeek()) {
            selectDateSelector(DATE_SELECTOR_NEXT_WEEK);
        }
        event.preventDefault();
    }

    const isDayToday = () => isToday(admissionListVars.date) && !admissionListVars.keyword && !admissionListVars.groupType;
    const isDayTomorrow = () => isTomorrow(admissionListVars.date) && !admissionListVars.keyword && !admissionListVars.groupType;
    const isDayAll = () => (admissionListVars.groupType === 'd') && (admissionListVars.groupId === DATE_SELECTOR_ALL);
    const isDayRecent = () => (admissionListVars.groupType === 'd') && (admissionListVars.groupId === DATE_SELECTOR_RECENT);
    const isDayThisMonth = () => (admissionListVars.groupType === 'd') && (admissionListVars.groupId === DATE_SELECTOR_THIS_MONTH);
    const isDayNextMonth = () => (admissionListVars.groupType === 'd') && (admissionListVars.groupId === DATE_SELECTOR_NEXT_MONTH);
    const isDayThisWeek = () => (admissionListVars.groupType === 'd') && (admissionListVars.groupId === DATE_SELECTOR_THIS_WEEK);
    const isDayNextWeek = () => (admissionListVars.groupType === 'd') && (admissionListVars.groupId === DATE_SELECTOR_NEXT_WEEK);
    const isDayPlus2 = () => (differenceInDays(admissionListVars.date, startOfToday() ) === 2) && !admissionListVars.keyword && !admissionListVars.groupId;
    const isDayPlus3 = () => (differenceInDays(admissionListVars.date, startOfToday()) === 3) && !admissionListVars.keyword && !admissionListVars.groupId;

    const dateSelectorTitle = !admissionListVars.date || isToday(admissionListVars.date) || isTomorrow(admissionListVars.date) ? 'Select Date' : format(admissionListVars.date, 'yyyy-MM-dd');
    const dateSelectorCount = !admissionListVars.date || isToday(admissionListVars.date) || isTomorrow(admissionListVars.date) ? '' : admissionListVars.admissions.length;

    const renderAllAdmissionsTab = () => {
        if (selectedHospital.manager_allAdmissonsTab_enable || state_showDevUI) {
            return (
                <li className="nav-item">
                    <a className={`nav-link${isDayAll() ? ' active' : ''}`}
                       href="#" onClick={selectAll}><span>All</span> {admissionListVars.count_all}</a>
                </li>
            )
        }
        return null;
    }
    const renderRecentBookingsTab = () => {
        if (selectedHospital.manager_recentTab_enable) {
            return (
                <li className="nav-item">
                    <a className={`nav-link${isDayRecent() ? ' active' : ''}`}
                       href="#" onClick={selectRecent}><span>Recent</span> {admissionListVars.count_recent}</a>
                </li>
            )
        }
        return null;
    }
    const currentMonth = () => format(new Date, 'MMMM');
    const nextMonth = () => format(addMonths(new Date, 1), 'MMMM');

    const renderThisNextMonthTab = () => {
        if (selectedHospital.manager_thisNextMonthTab_enable) {
            return (
                <>
                    <li className="nav-item">
                        <a className={`nav-link${isDayThisMonth() ? ' active' : ''}`}
                           href="#" onClick={selectThisMonth}><span>{currentMonth()}</span> {admissionListVars.count_thisMonth}</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link${isDayNextMonth() ? ' active' : ''}`}
                           href="#" onClick={selectNextMonth}><span>{nextMonth()}</span> {admissionListVars.count_nextMonth}</a>
                    </li>
                </>
            )
        }
        return null;
    }
    const renderThisNextWeekTab = () => {
        if (selectedHospital.manager_thisNextWeekTab_enable) {
            return (
                <>
                    <li className="nav-item">
                        <a className={`nav-link${isDayThisWeek() ? ' active' : ''}`}
                           href="#" onClick={selectThisWeek}><span>This Week</span> {admissionListVars.count_thisWeek}</a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link${isDayNextWeek() ? ' active' : ''}`}
                           href="#" onClick={selectNextWeek}><span>Next Week</span> {admissionListVars.count_nextWeek}</a>
                    </li>
                </>
            )
        }
        return null;
    }

    const extraFlags = () => {
        let flags = {};
        if (selectedHospital.manager_datePicker_limit_enable) {
            flags.maxDate = addDays(new Date(), selectedHospital.manager_datePicker_limit);
        }
        if (admissionListVars.date) {
            flags.selected = admissionListVars.date;
        }
        return flags;
    }

    return (
        <ul className="nav nav-tabs ajax-tabs procedure-list-tabs" role="tablist">
            <li className="nav-item">
                <a className={`nav-link${isDayToday() ? ' active' : ''}`}
                   href="#" onClick={selectToday}><span>Today</span> {admissionListVars.count_today}</a>
            </li>
            <li className="nav-item">
                <a className={`nav-link${isDayTomorrow() ? ' active' : ''}`}
                   href="#" onClick={selectTomorrow}><span>Tomorrow</span> {admissionListVars.count_tomorrow}</a>
            </li>
            <li className="nav-item">
                <a className={`nav-link${isDayPlus2(admissionListVars.date)? ' active' : ''}`}
                   href="#" onClick={selectDayPlus2}><span>{format(addDays(new Date(), '2'), 'eeee')}</span> {admissionListVars.count_dayPlus2}</a>
            </li>
            <li className="nav-item">
                <a className={`nav-link${isDayPlus3(admissionListVars.date) ? ' active' : ''}`}
                   href="#" onClick={selectDayPlus3}><span>{format(addDays(new Date(), '3'), 'eeee')}</span> {admissionListVars.count_dayPlus3}</a>
            </li>
            {renderThisNextWeekTab()}
            {renderThisNextMonthTab()}
            {renderAllAdmissionsTab()}
            {renderRecentBookingsTab()}
            {isCustomLogicLifehouse() ? <AdmissionList_Tab_Lifehouse /> : null}
            <li className="nav-item dropdown" ref={ref}>
                <a className={`nav-link${isToday(admissionListVars.date) || isTomorrow(admissionListVars.date) || admissionListVars.keyword || isDayPlus2() || isDayPlus3() ? '' : (admissionListVars.date ? ' active' : '')}`} href="#" onClick={showDatePopup}><span>{dateSelectorTitle}</span> {dateSelectorCount} <i
                    className="icon ico-small-down"></i></a>
                <div className={`date-dropdown-menu tabs-dropdown-menu dropdown-menu-right${datePopupVisible ? ' show' : ''}`} aria-labelledby="dropdownMenuButton">
                    <DatePicker
                        {...extraFlags()}
                        onChange={selectDate}
                        inline
                    />
                </div>
            </li>

            <AdmissionList_Tab_TestHospital />
        </ul>
    );
}

export default AdmissionList_Tabs;